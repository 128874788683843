@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  scroll-behavior: smooth;
  box-sizing: border-box;
  outline: none;
}

body{
    background-color: #FFFFFA;
    min-height: 100vh;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.outContainer{ 
  width: 100%;
  display: flex;
  justify-content: center;
}

.myContainer{
  padding: 2rem 4rem;
  max-width: 1900px;
  width: 100%;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .myContainer{
    padding: 1rem 1rem;
    display: flex;
  }
}

@media only screen and (max-width: 400px) {
  .myContainer{
    padding: 1rem 0.5rem;
    display: flex;
  }
}
