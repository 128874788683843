.text_gradient{
    background: #121619;
background: linear-gradient(to right, #121619 0%, #352D39 63%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.box_gradient{
    -webkit-box-shadow: 0px 8px 24px 0px rgba(66, 68, 90, 1);
-moz-box-shadow: 0px 8px 24px 0px rgba(66, 68, 90, 1);
box-shadow: 0px 8px 24px 0px rgba(66, 68, 90, 1);
}